<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        栏目配置
      </template>
      <template #input>
        <a-input placeholder="请输入栏目名称" v-model="title"  />
        <a-button class="all_btn_solid btn" type="primary" :loading="cacheload" @click="clearCache()">清除缓存</a-button>
        <a-button class="all_btn_solid btn" type="primary" :loading="loading" @click="getList()" v-hasPermi="['commodity:list:query']">搜索</a-button>
        <a-button class="all_boder_btn" @click="goAddPage" v-hasPermi="['category:commodity:edit']">新建栏目</a-button>
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :expandIcon="expandIcon"
      :columns="columns"
      :data-source="data"
      :defaultExpandAllRows="true"
      :pagination="false"
      :rowKey="item=>item.id"
      :loading="loading"
      childrenColumnName="subset"
    >
      <template slot="sort" slot-scope="item">
        <div>
          <span>{{item.sort}}</span> 
          <a-icon style="margin-left:10px" type="edit" @click="onOpenSortWindow(item)" />
        </div>
      </template>
      <template slot="catalogue" slot-scope="item,row">
        <a @click="editData(row)" v-hasPermi="['category:commodity:edit']">编辑</a>
        <span> | </span>
        <a @click="removeData(row)" v-hasPermi="['commodity:category:delete']">删除</a>
        <br>
        <a v-if="true" @click="newData(row)" v-hasPermi="['category:commodity:edit']">新建子栏目</a>
        <span v-show="row.pid"> | </span>
        <a v-show="row.pid" @click="goManage(row)">栏目详情管理</a>
      </template>
    </a-table>

    <!-- 新建/编辑 -->
    <a-modal v-model="visible" :title="(modelForm.id?'编辑':'新建') + (!!modelForm.pid?'子':'') + '栏目'" :confirm-loading="confirmLoading" @ok="setData()" okText="确认">
      <!-- 子分类 -->
      <a-row class="rows" v-show="!modelForm.id">
        <a-col span="5" class="left">当前栏目</a-col>
        <a-col span="19" class="right">
          <div style="line-height:32px;">{{levelName}}</div>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">子栏目名称</a-col>
        <a-col span="19" class="right">
          <a-input placeholder="请输入子栏目名称" v-model="modelForm.title"/>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">栏目编码</a-col>
        <a-col span="19" class="right flex" >
          <p v-if="pCode" style="margin-right: 10px;">{{ pCode }}</p>
          <a-input placeholder="可拼音描述栏目" v-model="modelForm.columnCode"/>
        </a-col>
      </a-row>
    </a-modal>

    <!-- 修改排序顺序 -->
    <a-modal v-model="sortShow" title="修改排序" :confirm-loading="confirmLoading" width="300px" @ok="onEditSort()" okText="修改">
      <a-row class="rows">
        <a-col span="5" class="left">排序号</a-col>
        <a-col span="14" class="right">
          <a-input-number  v-model="sortForm.sort" /> 
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "栏目",
    dataIndex: "title",
    width: '200px',
    key: "title",
  },
  {
    title: "栏目编码",
    dataIndex: "columnCode",
    width: '300px',
    key: "columnCode",
  },
  {
    title: "介绍",
    ellipsis: true,
    dataIndex: "detail",
    key: "detail",
  },
  {
    title: "备注",
    ellipsis: true,
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "栏目排序",
    align: "center",
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "栏目操作",
    align: "center",
    dataIndex: "catalogue",
    width: '200px',
    key: "catalogue",
    scopedSlots: { customRender: "catalogue" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      modelForm: {

      },
      title:undefined,
      pCode: '',
      levelName: '',
      visible: false,
      data: [],
      columns,
      loading: false,
      confirmLoading: false,
      cacheload:false,

      sortForm: {
        sort:'', // 绑定排序
        id:0
      },
      sortShow:false, // 排序弹窗
    };
  }, // 事件处理器
  methods: {
    expandIcon(props) {
      if(props.record.subset && props.record.subset.length > 0){
        if (props.expanded) {
          //有数据-展开时候图标
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-down" />{" "}
            </a>
          );
        } else {
          //有数据-未展开时候图标
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-right" />
            </a>
          );
        }
      }else{
        return <span style={{marginRight:8 }}></span>
      }
    },

    // 栏目大类创建
    goAddPage(){
      this.$router.push({path: '/admin/ContentConfig/columnAdd'})
    },

    // 栏目详情管理
    goManage(e){
      function findParent(data, target, result){
        for (let i in data) {
          let item = data[i]
          if (item.id === target) {
            //将查找到的目标数据加入结果数组中
            //可根据需求unshift(item.id)或unshift(item)
            result.unshift(item)
            return true
          }
          if (item.subset && item.subset.length > 0) {
            let ok = findParent(item.subset, target, result)
            if (ok) {
              result.unshift(item)
              return true
            }
          }
        }
        //走到这说明没找到目标
        return false
      }

      let result = []
      findParent(this.data, e.id, result)  

      let obj = result.length ? result[0] : {}
      this.$router.push({path: '/admin/ContentConfig/columnDetail?id=' + e.id + '&ftitle=' + obj.title + '&fremark=' + (obj.remark ? obj.remark : 0) })
    },

  
    onOpenSortWindow(e){
      this.sortForm = {
        id: e.id,
        sort: e.sort
      }
      this.sortShow = true
    },

    onEditSort(){
      if(!this.sortForm.sort){
        this.$message.error('请输入排序号')
        return false
      }
      this.confirmLoading = true
      this.$ajax({
        url: '/hxclass-management/ProductColumn/update',
          method: 'post',
          params: this.sortForm
        }).then(res=>{
          this.sortShow = false
          this.confirmLoading = false
          this.$message.success("修改成功");
          this.getList()
        })
    },

    // 查询列表数据
    getList(){
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/ProductColumn/tree',
        params:{
          title: this.title
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.data = res.data
        }
        this.loading = false
        this.name = ''
        this.levelName = ''
      }).catch(err=>{
        this.loading = false
        this.name = ''
        this.levelName = ''
      })
    },
    
    // 打开新建分类弹框
    newData(row) {
      this.pCode = row.columnCode + '-'
      this.modelForm = {
        pid: row.id
      }
      this.levelName = row.title
      this.visible=true;
    },
    
    // 打开编辑分类弹框
    editData(row) {
      if(row.pid){
        this.pCode = '';
        this.modelForm = {
          id: row.id,
          title: row.title,
          columnCode: row.columnCode
        }
        this.visible=true;
      } else {
        this.$router.push({path: '/admin/ContentConfig/columnAdd?id=' + row.id})
      }
    },

    // 新增/修改分类
    setData(){
      if(!this.modelForm.title){
        this.$message.error('请输入子栏目名称')
        return false
      }
      if(!this.modelForm.columnCode){
        this.$message.error('请输入子栏目编码')
        return false
      }
      this.confirmLoading = true
      this.$ajax({
        url: '/hxclass-management/ProductColumn/update',
        method: 'post',
        params: this.modelForm
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$message.success("操作成功");
          this.getList()
          this.visible = false
        }else{
          this.$message.error(res.message)
        }
        this.confirmLoading = false
      })
    },

    // 清除缓存
    clearCache(){
      this.cacheload = true
      this.$ajax({
        url: '/hxclass-management/ProductColumn/clear/cache',
        method: 'delete'
      }).then(res=>{
        this.cacheload = false
        this.$message.success("清除成功");
      })
    },

    // 删除分类
    removeData(e){
      if(e.subset && e.subset.length){
        this.$message.error("当前栏目存在子栏目，不允许删除");
        return
      }
      this.$confirm({
        title: '确定删除该标签吗?',
        okText: '确认',
        onOk:()=> {
          this.$ajax({
            url: '/hxclass-management/ProductColumn/delete/'+e.id,
            method: 'delete'
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.$message.success("删除成功");
              this.getList()
            }else{
              this.$message.error(res.message)
            }
          })
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getList()
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap{
  min-height: 100%;
  .btn{
    margin: 0 24px 14px 0;
  }
  a{
    color: @theme;
  }
}
.rows{
  margin-bottom: 20px;
  display: flex;
  &:last-child{
    margin-bottom: 0;
  }
  .left{
    text-align: right;
    height: 32px;
    line-height: 32px;
    min-width: 100px;
    &::before{
      content: '*';
      color: red;
      margin-right: 2px;
    }
    &::after{
      content: '：';
    }
  }
  .flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input{
      flex: 1;
    }
    a{
      margin-left: 8px;
    }
  }
}
</style>
